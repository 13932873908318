import React, { Component } from 'react';
import LoginPage from './components/LoginPage.jsx'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { blue, red } from '@material-ui/core/colors';
/*import {Grid, Typography, AppBar} from '@material-ui/core'*/

// Add Theme as wrapper around Main Page
const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: red[700],
    },
  },
});

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <LoginPage/>
      </ThemeProvider>
    );
  }
}

export default App;
